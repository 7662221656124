<template>
  <v-container class="mt-16">
    <div class="">
      <v-card flat>
        <v-card-title class="d-flex">
          <h2>Accounts</h2>
          <v-spacer></v-spacer>
          <add-account-modal />
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="Search for something"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            placeholder="John Doe"
          ></v-text-field>
          <p>List of registered accounts:</p>
          <v-data-table
            :search="search"
            class="mt-5"
            :items="users"
            :headers="headers"
            v-if="users"
            :loading="loading"
          >
            <template v-slot:item.actions="{ item }">
              <div class="d-flex">
                <edit-account-modal :item="item" />
                <v-icon color="red" @click="del(item)">mdi-delete</v-icon>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import addAccountModal from "../components/Account/addAccountModal.vue";
import EditAccountModal from "../components/Account/editAccountModal.vue";
export default {
  components: { addAccountModal, EditAccountModal },
  data() {
    return {
      search: "",
      loading: false,
      headers: [
        { text: "", value: "actions" },
        { text: "ID", value: "id" },
        { text: "username", value: "username" },
        { text: "Full Name", value: "full_name" },
        { text: "Address", value: "address" },
        { text: "Phone Number", value: "phone_number" },
        { text: "Created at", value: "created_at" },
        { text: "Role", value: "role" },
        { text: "Access Level", value: "access_level" },
        { text: "Branch", value: "branch.branch_name" },
        { text: "Is Active", value: "is_active" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      users: "auth/users",
    }),
  },
  created() {
    this.get_users();
  },
  methods: {
    ...mapActions({
      get_users: "auth/get_users",
    }),
    del(item) {
      console.log(item);
    },
  },
};
</script>

<style></style>
