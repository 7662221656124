<template>
  <div>
    <v-btn color="green" class="" icon x-large @click="dialog = true"
      ><v-icon>mdi-plus-box</v-icon></v-btn
    >
    <v-dialog v-model="dialog" persistent width="500">
      <v-card class="pa-5">
        <v-card-title> <h2>Add Account</h2></v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              label="Username"
              v-model="form.username"
              prepend-icon="mdi-account-circle"
            ></v-text-field>
            <v-text-field
              label="Password"
              v-model="form.password"
              prepend-icon="mdi-form-textbox-password"
            ></v-text-field>
            <v-text-field
              label="Full Name"
              v-model="form.full_name"
              prepend-icon="mdi-account-circle-outline"
            ></v-text-field>
            <v-text-field
              label="Address"
              v-model="form.address"
              prepend-icon="mdi-map-marker"
            ></v-text-field>
            <v-text-field
              label="Phone Number"
              v-model="form.phone_number"
              prepend-icon="mdi-phone"
            ></v-text-field>
            <v-select
              :items="roles"
              label="Role"
              v-model="form.role"
              prepend-icon="mdi-account-hard-hat"
            ></v-select>
            <v-select
              prepend-icon="mdi-domain"
              :items="branches"
              label="Branch"
              item-text="branch_name"
              item-value="id"
              v-model="form.branch_id"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="submit" :loading="loading">Add</v-btn>
          <v-btn @click="dialog = false">Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">addAccountModal.vue</p>
        </v-card-actions>
      </v-card></v-dialog
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      roles: ["Admin", "Staff", "Technician", "Reseller"],
      loading: false,
      ready: false,
      dialog: false,
      form: {},
    };
  },
  methods: {
    ...mapActions({
      add_account: "auth/add_user",
      get_branches: "branch/get_branches",
    }),
    submit() {
      this.loading = true;
      let x = window.confirm("Are you sure you want to add this account?");
      if (x) {
        this.add_account(this.form).then(() => {
          alert("Successfully added a new account");
          this.loading = false;
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      branches: "branch/branches",
    }),
  },
  async created() {
    if (this.branches.length == 0) return this.get_branches();
  },
};
</script>
<style lang="scss" scoped></style>
