<template>
  <div>
    <v-icon color="yellow" class="" icon @click="dialog = true"
      >mdi-pencil</v-icon
    >
    <v-dialog v-model="dialog" persistent width="500">
      <v-card class="pa-5">
        <v-card-title> <h2>Edit Account</h2></v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              label="Username"
              v-model="item.username"
              prepend-icon="mdi-account-circle"
            ></v-text-field>
            <v-text-field
              label="Password"
              v-model="item.password"
              prepend-icon="mdi-form-textbox-password"
            ></v-text-field>
            <v-text-field
              label="Full Name"
              v-model="item.full_name"
              prepend-icon="mdi-account-circle-outline"
            ></v-text-field>
            <v-text-field
              label="Address"
              v-model="item.address"
              prepend-icon="mdi-map-marker"
            ></v-text-field>
            <v-text-field
              label="Phone Number"
              v-model="item.phone_number"
              prepend-icon="mdi-phone"
            ></v-text-field>
            <v-select
              :items="roles"
              label="Role"
              v-model="item.role"
              prepend-icon="mdi-account-hard-hat"
            ></v-select>
            <v-select
              prepend-icon="mdi-domain"
              :items="branches"
              label="Branch"
              item-text="branch_name"
              item-value="id"
              v-model="item.branch_id"
            ></v-select>
            <v-select
              :items="[0, 1]"
              label="Is Active"
              v-model="item.is_active"
              prepend-icon=""
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="submit" :loading="loading">Edit</v-btn>
          <v-btn @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      roles: ["Admin", "Staff", "Technician", "Reseller"],
      loading: false,
      ready: false,
      dialog: false,
      form: {},
    };
  },
  methods: {
    ...mapActions({
      edit_account: "auth/edit_user",
    }),
    submit() {
      this.loading = true;
      let x = window.confirm("Are you sure you want to edit this account?");
      if (x) {
        this.edit_account(this.item).then(() => {
          alert("Successfully edited a new account");
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      branches: "branch/branches",
    }),
  },
  async created() {},
};
</script>

<style lang="scss" scoped></style>
